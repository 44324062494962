
<script setup>
    import { computed } from "vue";

    const props = defineProps({
        to     : {type: String  },
        onClick: {},
    });
    const emit = defineEmits(["click"]);

    const clickable = computed(() => {
        return props.to || props.onClick;
    });
</script>

<template>
    <component :is="props.to && 'RouterLink' || 'a'" class="afm-card" :class="{ 'afm-card-clickable': clickable }" :to="props.to" v-bind="$attrs" @click="(...args) => emit('click', ...args)">
        <slot />
    </component>
</template>

<style scoped>
    .afm-card {
        display: block;
        position: relative;

        color: black;
        background-color: white;
        background-color: var(--bs-white);
        border: 1px solid rgb(90%, 90%, 90%);
        border: 1px solid var(--bs-gray-300);

        text-decoration: none;

        padding: 0.75rem;

        border-radius: 0.5rem;
        box-shadow: 2px 4px 8px rgba(0%, 0%, 0%, 5%);
    }

    .afm-card-clickable {
        transition: background 200ms ease-in-out;
        &:active {
            transition: background 0ms;
            background-color: var(--bs-gray-300);
        }
    }

    .afm-card:before {
        position: absolute;
        inset: 0;
        background-color: rgb(50%, 50%, 50%);
        opacity: 0%;
        transition: all 200ms ease-in-out;
    }
    .afm-card:hover:before {
        opacity: 10%;
    }
</style>
